.answersBoxOuterContainer {
    position: relative;
    left: calc(50vw - 35.55vw);
    max-width: 70vw;
    padding-bottom: 10vh;
    margin-top: 20px;
}
@keyframes reveal2 {
    from {
        transform: scale(0) rotate(480deg);
    }
    to {
        transform: scale(1) rotate(0deg);
    }
}
@keyframes flip {
    0% {
        transform: scaleY(0);
    }

    50% {
        background-color: white;
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(1);
    }
}
@keyframes cardFlip {
    0% {
        transform: perspective(1000px) rotateX(100deg);
    }
    50% {
        transform: perspective(1000px) rotateX(-20deg);
    }
    100% {
        transform: perspective(1000px) rotateX(0deg);
    }
}
.entriesBox * *:not(:last-child) {
    animation: cardFlip 0.5s forwards;
    /* transition: all 2s; */
}
.entriesBox *:nth-child(1) {
    animation-delay: 100ms;
}
.entriesBox *:nth-child(2) {
    animation-delay: 400ms; /* 100ms + 300ms */
}
.entriesBox *:nth-child(3) {
    animation-delay: 700ms; /* 400ms + 300ms */
}
.entriesBox *:nth-child(4) {
    animation-delay: 1000ms; /* 700ms + 300ms */
}
.entriesBox *:nth-child(5) {
    animation-delay: 1300ms; /* 1000ms + 300ms */
}
.entriesBox *:nth-child(6) {
    animation-delay: 1600ms; /* 1300ms + 300ms */
}
.entriesBox *:nth-child(7) {
    animation-delay: 1900ms; /* 1600ms + 300ms */
}
.attrLabelWrapper {
    color: #deebff;
    display: inline-flex;
    width: 100%;
    justify-content: space-evenly;
    font-size: 1.5em;
    /* text-decoration: underline; */
    /* border: 1px dotted yellow; */
}
.attrLabelWrapper * {
    width: 8vw;
    text-align: center;
    font-size: 0.7em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    /* border: 1px dotted green; */
}
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    /* width: 60px; */
    height: 34px;
    border-bottom: none;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.metricLabel {
    border-bottom: none;
    position: relative;
    left: 2.6vw;
    font-size: 1.1em;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    width: 4vw;
    height: 2vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 1.5vw;
    width: 1.5vw;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(2vw);
    -ms-transform: translateX(2vw);
    transform: translateX(2vw);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

@media all and (max-width: 500px) {
    .answersBoxOuterContainer {
        max-width: 100vw;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        left: 0;
    }
    .attrLabelWrapper {
        width: 100% !important;
        font-size: 0.84em;
    }
    .attrLabelWrapper div {
        /* border: 1px dotted red; */
        width: 15vw !important;
    }
    .entriesBox * * {
        animation: cardFlip 0.5s ease-out forwards;
        /* transition: all 2s; */
    }
}
@media all and (min-device-width: 1000px) {
    .answersBoxOuterContainer {
        margin-top: 50px;
        min-width: 842px;
    }
}