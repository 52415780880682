@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.gameModeSelector-wrapper {
    width: 50%;
    margin: auto;
    padding: 2%;
    text-align: center;
    color: #efefef;
    animation: fadeIn 0.5s forwards;
}
.gameModeSelector-wrapper.animated {
    opacity: 0;
    animation-delay: 2.7s;
}
.gameModeSelector-orSelect {
    font-size: 1.1em;
    font-weight: 100 !important;
}
.gameModeSelector-list {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.gameModeSelector-list li,
.gameModeSelector-list-item-wrapper {
    color: black;
    padding: 2vw 0;
    width: 50%;
    background-color: #efefef;
    font-size: 16pt;
    margin: 0 0.5%;
    /* disable select */
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.gameModeSelector-list-item-wrapper {
    overflow: hidden;
    padding: 0;
}
.gameModeSelector-list li {
    transition: all 0.2s;
}
.gameModeSelector-list li:hover {
    cursor: pointer;
    background-color: #dad8d8;
}
.gameModeSelector-list-multi {
    margin: 0;
    flex-wrap: nowrap;
}
.gameModeSelector-list-multi li {
    width: 100%;
    text-align: center;
    padding: 2vw 0;
    flex-grow: 1;
    background-size: contain;
    background-repeat: no-repeat;
}
.gameModeSelector-list-multi li.active {
    width: 130%;
    transform: scale(1.1);
    background-position: 10%;
    transition: all 0.5s;
}
@media all and (max-device-width: 500px) {
    .gameModeSelector-wrapper {
        width: 90%;
    }
    .gameModeSelector-list li:hover {
        background-color: white;
    }
    .gameModeSelector-list,
    .gameModeSelector-list li,
    button {
        font-size: 16px;
        width: 100%;
    }
    .gameModeSelector-list-multi {
        margin: 0;
    }
    .gameModeSelector-list-multi li {
        width: 100%;
        text-align: center;
        padding: 10vw 0;
        background-size: 60%;
    }
    .gameModeSelector-list li.active {
        color: transparent;
    }
}
