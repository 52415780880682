@import url('https://fonts.googleapis.com/css2?family=Square+Peg&display=swap');
* {
    font-family: Arial, Helvetica, sans-serif;
}
@keyframes pokeballShake {
    0%,
    50% {
        transform: rotate(0deg);
    }
    15% {
        transform: rotate(-10deg);
    }
    30% {
        transform: rotate(10deg);
    }
    40% {
        transform: rotate(-5deg);
    }
    100% {
        transform: translateY(-150px) translateX(-1000px);
    }
}
@keyframes pokeBallRoll {
    0% {
        transform: rotate(0deg);
        left: -1000px;
    }
    90% {
        transform: rotate(1820deg);
        left: 0.8%;
    }
    100% {
        transform: rotate(1800deg);
        left: 0;
    }
}
@keyframes kedle {
    0% {
        left: 1000px;
    }
    100% {
        left: 0px;
    }
}
@keyframes p {
    0% {
        right: 1000px;
    }
    100% {
        right: 0px;
    }
}
@keyframes fade {
    0% {
        color: 0;
    }
    100% {
        opacity: 1;
    }
}
*::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
body {
    background-color: rgb(66, 66, 66);
}
body.modal-open {
    overflow: hidden;
    position: fixed;
}
.headerText {
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 2em;
    color: white;
    font-weight: 100;
    letter-spacing: 0.5em;
    font-style: italic;
    text-align: center;
}
.headerText:hover {
    cursor: pointer;
}
.header {
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18vh;
}
.header * {
    font-family: 'Square Peg', cursive;
    position: relative;
    /* border: 1px solid; */
}
.header .pokeball {
    left: 0;
    animation: pokeBallRoll 2s forwards ease-out;
    vertical-align: -20%;
    width: 1.3em;
}
.header .p,
.header .kedle *,
.header .settingsIcon {
    font-size: 2em;
    display: inline-block;
    animation-iteration-count: 2 !important;
    opacity: 0;
}
.header .kedle {
    left: 0.3vw;
}
.header .kedle,
.p,
.header .modalTargetIcon {
    /* left: 1000px; */
    position: relative;
}
.header .modalTargetIcon,
.header .p,
.header .kedle * {
    animation: fade 0.2s forwards;
    animation-iteration-count: 1;
}
.header .p {
    animation-delay: 2.22s;
}
.header .kedle h1:nth-child(1) {
    animation-delay: 2.24s;
}
.header .kedle h1:nth-child(2) {
    animation-delay: 2.26s;
}
.header .kedle h1:nth-child(3) {
    animation-delay: 2.28s;
}
.header .kedle h1:nth-child(4) {
    animation-delay: 2.3s;
}
.header .kedle h1:nth-child(5) {
    animation-delay: 2.32s;
}
.header .modalTargetIcon {
    animation-delay: 2.34s;
}
.modalTargetIcon {
    animation-delay: 2.5s;
    width: 1.5vw;
    position: relative;
    filter: invert() brightness(200);
    opacity: 0;
}
.modalTargetIcon:hover {
    cursor: pointer;
}
.pageSubheading {
    color: white;
    text-align: center;
}
.pageSubheading * {
    font-weight: 100;
}
.pageSubheading h1 {
    font-size: 1.5em;
}
.pageSubheading p {
}
.pageWrapper {
    opacity: 0;
    animation: fade 0.3s forwards;
    margin-top: 2%;
}
.pokeball:hover {
    animation: pokeballShake forwards 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@media all and (max-width: 500px) {
    .header {
        margin-top: 15vh;
    }
    .pokeball {
        width: 8vw !important;
    }
    .pageSubheading {
        padding: 5% 0;
    }
    .pageSubheading h1 {
        font-size: 1em;
    }
    .pageSubheading p {
        font-size: 0.8em;
    }
    .modalTargetIcon {
        width: 6vw;
    }
}
