@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(90deg);
    }
}
.settingsModal-target .modalTargetIcon {
    right: 3vw;
    transition: all 0.3s;
}
.settingsModal-target .modalTargetIcon:hover {
    transform: rotate(90deg);
}
.modal .settings-menu-options li {
    margin-bottom: 5vw;
    text-align: left;
    display: block;
}

.metric-switch-mobile .switch {
    vertical-align: middle;
    position: relative;
    display: inline-block;
    /* width: 60px; */
    height: 34px;
    border-bottom: none;
}

/* Hide default HTML checkbox */
.metric-switch-mobile .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.metric-switch-mobile .metric-label {
    margin: 0px;
    left: 0px;
    border-bottom: none;
    position: relative;
}

/* The slider */
.metric-switch-mobile .slider {
    position: absolute;
    cursor: pointer;
    width: 15vw;
    height: 7vw;
    left: 150% !important;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.metric-switch-mobile .slider:before {
    position: absolute;
    content: '';
    height: 5vw;
    width: 5vw;
    left: 0px;
    bottom: 1vw;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.metric-switch-mobile input:checked + .slider {
    background-color: #2196f3;
}

.metric-switch-mobile input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

.metric-switch-mobile input:checked + .slider:before {
    -webkit-transform: translateX(3vw);
    -ms-transform: translateX(3vw);
    transform: translateX(8vw);
}

/* Rounded sliders */
.metric-switch-mobile .slider.round {
    border-radius: 30px;
}

.metric-switch-mobile .slider.round:before {
    border-radius: 50%;
}

.slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    transform: translateX(30px); /* Adjust handle position */
}

@media all and (max-width: 500px) {
    .settingsModal {
        border: 1px solid;
        display: flex;
        justify-content: center;
    }
    .settingsModal ul {
        position: relative;
        left: -100px;
    }
}