#pokeSelectMenuWrapper {
    margin-top: 1%;
}
#pokeSelectMenuWrapper div {
    position: relative;
}
.css-13cymwt-control,
.css-13cymwt-control,
.css-1nmdiq5-menu,
.css-t3ipsp-control {
    left: calc(50vw - 35.55vw);
    background-color: #efefef !important;
}
.css-1nmdiq5-menu,
.css-1fdsijx-ValueContainer,
.css-hlgwow {
    font-size: 1.5em;
}
.css-1fdsijx-ValueContainer *,
.css-hlgwow * {
    vertical-align: middle;
    padding-left: 1.5%;
    padding-right: 3.7%;
}
.css-1n6sfyn-MenuList,
.css-qr46ko,
.css-1nmdiq5-menu,
.css-13cymwt-control,
.css-t3ipsp-control {
    max-width: 70vw;
}
.css-1n6sfyn-MenuList div,
.css-qr46ko div {
    padding-left: 3%;
}
.css-1n6sfyn-MenuList *,
.css-qr46ko * {
    vertical-align: middle;
}
.css-1n6sfyn-MenuList img,
.css-qr46ko img {
    padding-right: 3%;
}

@media all and (max-width: 500px) {
    #pokeSelectMenuWrapper {
        margin-top: 5%;
    }
    .css-1n6sfyn-MenuList,
    .css-qr46ko,
    .css-1nmdiq5-menu,
    .css-13cymwt-control,
    .css-t3ipsp-control {
        max-width: 100vw;
    }
    .css-13cymwt-control,
    .css-1nmdiq5-menu,
    .css-t3ipsp-control {
        left: 0;
    }
    .css-1nmdiq5-menu img {
        width: 20vw;
        margin-right: 5%;
        vertical-align: middle;
    }
}
@media all and (min-device-width: 1000px) {
    .css-1nmdiq5-menu,
    .css-13cymwt-control,
    .css-1n6sfyn-MenuList,
    .css-qr46ko {
        min-width: 842px;
    }
}
