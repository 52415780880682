@keyframes shake {
    0%,
    100% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
    75% {
        transform: rotate(-5deg);
    }
}
.red {
    color: #dc5a5a;
}
.yellow {
    color: #dbc379;
}
.green {
    color: #78a55a;
}
.helpModal {
    max-height: 85vh;
    overflow-y: scroll;
    text-align: center;
    padding: 3%;
}
.helpModal-header {
    display: inline-block;
    width: 50%;
    padding: 1%;
    font-size: 1.3em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 1%;
}
.helpModal-p {
    text-align: left;
}
.helpModal-p .hint {
    margin-top: -2%;
    font-size: 0.7em;
}
.helpModal-target {
    left: 3vw;
}
.helpModal-target:hover {
    animation: shake 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media all and (max-width: 750px) {
    .helpModal {
        top: 5%;
    }
    .helpModal-body {
        padding-bottom: 20%;
    }
    .helpModal * {
        font-size: 1em;
    }
}
@media all and (min-width: 750px) {
    .helpModal,
    .helpModal-body {
        width: 50vw;
    }
}
