.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure the modal appears above other elements */
}

.modal {
    width: 80vw;
    background-color: #efefef;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: slide-in 0.3s forwards;
}

.modal * {
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: normal;
    color: black;
    font-size: 16pt;
}
@keyframes slide-in {
    from {
        opacity: 0;

        transform: translateY(-5%);
    }
    to {
        opacity: 1;

        transform: translateY(0);
    }
}

.close-btn {
    color: #aaa;
    font-size: 30px;
    margin-left: -30px;
    float: right;
    z-index: 5;
}

.close-btn:hover {
    color: black;
    cursor: pointer;
}

button {
    transition: all 0.2s;
    padding: 1.5% 0;
    width: 99.5%;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 0 0 5px 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0373ec;
    cursor: pointer;
}

@media all and (min-device-width: 501px) {
    .modal {
        width: 50%;
    }
    button {
        font-size: 1em;
        margin-top: 0.3%;
        width: 99%;
    }
}
@media all and (max-device-width: 500px) {
    .modal {
        width: 85vw;
        padding: 5vw !important;
    }
    .close-btn {
        position: absolute;
        left:11%;
        font-size: 25px !important
    }
    button {
        font-size: 1em;
        margin-top: 0.3%;
        width: 99%;
    }
}
