@keyframes fadeInAndGrow {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    60% {
        opacity: 1;
        transform: scale(1.2); /* Bounce effect - Scale to 1.2 */
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes growAndStay {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}
@keyframes magnifying {
    0% {
        transform: rotate(0deg) translate(0px, 0px);
    }
    100% {
        transform: rotate(-10deg) translate(-3px, 2px);
    }
}
.hintsBox-wrapper-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
}
.hintsBox-wrapper-outer .effectivenessText {
    line-height: 200%;
    text-align: center;
    color: #efefef;
}
.hintsBox-wrapper-outer .effectivenessText-label {
    font-size: 0.8em;
    text-align: center;
    color: #efefef;
}
.hintsBox-wrapper {
    width: 20%;
    height: 9vh;
    padding: 1%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow:
        0 2px 4px rgb(0 0 0 /20%) inset,
        0 8px 16px rgb(0 0 0 / 10%) inset;
}
.hintsBox-wrapper img {
    z-index: 2;
    opacity: 1;
}
.colorSchemeBox-wrapper {
    justify-content: center;
    display: flex;
    height: 70%;
    width: 100%;
    flex-wrap: wrap;
}
.colorSchemeBox-wrapper .colorBox-wrapper {
    z-index: 1;
    height: 100%;
    flex-grow: 1;
    opacity: 1;
    transform: scale(1);
}
.colorBox-wrapper {
    transition: all ease-in-out 0.1s;
    transform: scale(1);
}
.colorBox-wrapper .colorBox {
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: scale(0);
    animation: fadeInAndGrow 0.3s ease-in-out forwards;
}
/* Apply the animation to each box with a delay */
.colorBox.colorBox-1 {
    animation-delay: 0.025s;
}

.colorBox.colorBox-2 {
    animation-delay: 0.05s;
}

.colorBox.colorBox-3 {
    animation-delay: 0.075s;
}

.colorBox.colorBox-4 {
    animation-delay: 0.1s;
}

.colorBox.colorBox-5 {
    animation-delay: 0.125s;
}
.hintPromptBox {
    display: flex;
    align-items: center;
    height: 70%;
}
.hintText {
    flex-basis: 100%;
    color: white;
    font-size: 0.9em;
    height: 30%;
}
.hintsBox-wrapper .modalTargetIcon {
    position: relative;
    transition: all 1s;
    transform: translate(0px, 0px) rotate(0deg);
}
.hintsBox-wrapper .modalTargetIcon:hover {
    animation: magnifying 1s forwards;
}
@media all and (min-width: 501px) {
    .colorBox-wrapper:hover {
        cursor: pointer;
        animation: growAndStay 1ms forwards;
        z-index: 2;
    }
}
@media all and (max-width: 500px) {
    .hintsBox-wrapper {
        position: relative;
        padding: 5% 10vw;
        width: 76vw;
        height: 9vh;
    }
    .colorSchemeBox-wrapper {
        width: 100%;
    }
    .hintsBox-wrapper-outer {
        flex-wrap: wrap;
    }
    .hintsBox-wrapper-outer .effectivenessText-label {
        font-size: 0.4em;
    }
}
