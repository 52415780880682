@import url('https://fonts.googleapis.com/css2?family=Square+Peg&display=swap');
@keyframes fade_modal {
    0% {
        opacity: 0;
        top: -100px;
    }
    100% {
        opacity: 1;
        top: 0px;
    }
}

.victoryScreenWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
.victoryScreenCard h1 {
    margin: 0;
    font-size: 50px;
    font-family: 'Square Peg', Arial, Helvetica, sans-serif;
}
.victoryScreenCard {
    animation: fade_modal 0.5s forwards ease-out;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    height: max-content;
    text-align: center;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 56%,
        rgba(127, 255, 187, 1) 100%
    );
    border-radius: 10px;
    box-shadow:
        0 2px 4px rgb(0 0 0 / 10%),
        0 8px 16px rgb(0 0 0 / 10%);
    padding: 5% 0;
}
.pokemonGuessed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}
.pokemonGuessedImageAndText {
    display: flex;
    flex-basis: 100%;
    align-items: center;
}
.pokemonGuessedImageAndText img {
    width: 10vw; /* Adjust the width to make the images bigger */
}

.pokemonGuessed .pokemonGuessedText h3:nth-of-type(1) {
    font-size: 13pt;
    color: red;
}

.numOfTries {
    letter-spacing: 1pt;
    display: block;
}
.countdownWrapper {
    font-weight: bold;
    padding: 5%;
    font-size: 1.2em;
}
@media screen and (max-width: 500px) {
    .victoryScreenCard {
        width: 95vw;
    }
    .pokemonGuessedImageAndText .pokemonGuessedImage {
        width: 30vw;
    }
}
